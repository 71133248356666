<template>
  <section id="dashboard-ecommerce">
    <b-row>
      <b-col>
        <b-card>
          <b-card-text>
            <DxGrid
              ref="dxGridRef"
              title="Company Groups"
              canSelect="none"
              :data-source="dataSource"
              :total-count="totalCount"
              @on-delete="(v) => onDelete(v)"
              @on-open-modal="(v) => onOpenModal(v)"
              @on-update-cancel="(v) => loadCompanyGroups()"
              @on-page-change="(v) => (page = v)"
              @on-size-change="(v) => (size = v)"
            >
              <template #columns>
                <DxColumn
                  :data-field="item.field"
                  :caption="item.caption"
                  v-for="(item, index) in fields"
                  :key="index"
                />
              </template>
            </DxGrid>
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>

    <b-modal
      hide-footer
      v-model="modalShow"
      centered
      @hide="closeModal()"
      size="lg"
    >
      <validation-observer ref="refCategory" #default="{ invalid }">
        <b-row>
          <b-col>
            <validation-provider
              #default="{ errors }"
              name="Category Name"
              vid="v-category_name"
              rules="required"
            >
              <b-form-group label="Name" label-for="v-name">
                <b-form-input
                  :class="errors.length > 0 ? 'is-invalid' : null"
                  v-model="formData.name"
                  name="name"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>

        <b-row>
          <b-col class="text-right">
            <b-form-group>
              <b-button
                size="sm"
                @click="closeModal()"
                variant="danger"
                class="bg-darken-4 mr-1"
              >
                Cancel
              </b-button>
              <b-button
                :disabled="invalid"
                size="sm"
                @click="onSubmitCategory()"
                variant="primary"
                class="bg-darken-4"
              >
                Save
              </b-button>
            </b-form-group>
          </b-col>
        </b-row>
      </validation-observer>
    </b-modal>
  </section>
</template>

<script>
import companyGroupsApi from '@api/company_groups';
import { required } from '@validations';
import { queryParameters } from '@/schema';

export default {
  name: 'CompanyGroupPage',
  components: {},
  data: () => ({
    required,
    modalShow: false,
    dataSource: [],
    selectedData: [],
    totalCount: 0,
    page: 0,
    size: 0,
    fields: [{ field: 'name', caption: 'Name' }],

    formData: {
      id: 0,
      name: '',
      description: '',
    },
  }),
  watch: {
    page(v) {
      this.loadCompanyGroups();
    },
    size(v) {
      if (v !== queryParameters.page.size) {
        this.loadCompanyGroups();
      }
    },
  },
  mounted() {
    this.loadCompanyGroups();
  },
  methods: {
    loadCompanyGroups() {
      const params = {
        sort: queryParameters.sort,
        page: queryParameters.page,
        status: queryParameters.status,
      };

      params.page.size = this.size;
      params.page.after = this.page;

      companyGroupsApi
        .list(params)
        .then(({ data, meta }) => {
          if (data != null) {
            this.dataSource = data;
          }

          this.totalCount = meta.page.total;
        })
        .catch((err) => {
          //
        })
        .finally(() => {
          //
        });
    },
    onSubmitCategory() {
      const closeModalloadCompanyGroups = () => {
        this.loadCompanyGroups();
        this.closeModal();
      };

      this.$refs.refCategory.validate().then((success) => {
        if (success) {
          if (this.formData.id) {
            companyGroupsApi
              .update(this.formData)
              .then(({ data }) => {
                closeModalloadCompanyGroups();
              })
              .catch(() => {
                //
              })
              .finally(() => {
                //
              });

            return;
          }

          companyGroupsApi
            .add(this.formData)
            .then(({ data }) => {
              closeModalloadCompanyGroups();
            })
            .catch(() => {
              //
            })
            .finally(() => {
              //
            });
        }
      });
    },
    onOpenModal(event) {
      this.modalShow = event.value;

      if (event.name === 'edit') {
        this.onEdit(event.data);
      }
    },
    onEdit(data) {
      this.$nextTick(() => {
        this.formData = Object.assign({}, data);
      });
    },
    onDelete(event) {
      companyGroupsApi
        .delete(event)
        .then(() => {
          this.loadCompanyGroups();
        })
        .catch(() => {
          //
        })
        .finally(() => {
          //
        });
    },
    closeModal() {
      this.modalShow = false;
      const defaultForm = {
        id: 0,
        name: '',
        description: '',
      };

      this.$nextTick(() => {
        this.formData = Object.assign({}, defaultForm);
      });
    },
  },
};
</script>

<style></style>
